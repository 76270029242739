let numerologiesDetail = []
let allNumerologies = []

const getDetailNumerology = (type, number) => {
  const numerologiesByType = allNumerologies.filter(numerology => numerology.type === type)
  const typeDefine = numerologiesByType.find(numerology => numerology.define === 'define')
  const numberItem = numerologiesByType.find(numerology => numerology.number === Number(number))
  if (typeDefine && numberItem) {
    numerologiesDetail.push(`#${numberItem.title}`, ...typeDefine.content, '-----')
    if (number > 9 && type !== 1) {
      const shortenedNumber = Math.floor(number / 10) + (number % 10)
      const numberNormalItem = numerologiesByType.find(numerology => numerology.number === Number(shortenedNumber))
      numerologiesDetail.push(`*Shortened number: ${shortenedNumber}`, ...numberNormalItem.content, '=>', `*${numberItem.title}`)
    }
    numerologiesDetail.push(...numberItem.content)
  }
}

const NumerologyContent = props => {
  const {
    numerologies, specialNumerologyCount, birthNumber, karmicNumber, lifePathNumber, destinyNumber, soulNumber, personalityNumber, karmicLessons, maturityNumber, attitudeNumber, personalYearNumber,
  } = props

  numerologiesDetail = []
  allNumerologies = numerologies
  numerologiesDetail.push(`#Special count: ${specialNumerologyCount}`, '')
  getDetailNumerology(0, birthNumber)
  getDetailNumerology(1, karmicNumber)
  getDetailNumerology(2, lifePathNumber)
  getDetailNumerology(3, destinyNumber)
  getDetailNumerology(4, soulNumber)
  getDetailNumerology(5, personalityNumber)

  const karmicLessonsItem = numerologies.filter(numerology => numerology.type === 6 && karmicLessons.includes(`${numerology.number}`))
  karmicLessonsItem.forEach(karmicLessonItem => {
    numerologiesDetail.push(`#${karmicLessonItem.title}`, ...karmicLessonItem.content, '')
  })

  getDetailNumerology(7, maturityNumber)
  getDetailNumerology(8, attitudeNumber)
  getDetailNumerology(9, personalYearNumber)

  return numerologiesDetail
}

export default NumerologyContent

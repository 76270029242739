<template>
  <b-card-code class="person-info-page">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between flex-wrap">
      <b-form-group>
        <b-link
          target="_blank"
          class="btn btn-primary mr-1"
          href="/person-infos/create"
        >
          Create
        </b-link>
        <b-button
          class="btn btn-white mr-1"
          @click="downloadCSV"
        >
          CSV
        </b-button>
        <b-button
          class="btn btn-warning"
          @click="fetchPersonInfos()"
        >
          <feather-icon
            icon="RefreshCwIcon"
            size="14"
          />
        </b-button>
      </b-form-group>

      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            v-debounce:300ms="handleSearch"
            placeholder="Search"
            min-width="10rem"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="personInfos && personInfos.data"
      :pagination-options="{
        enabled: true,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'dob_left_days', type: 'asc' },
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: id -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.id }}
        </span>

        <!-- Column: first_name -->
        <span v-else-if="props.column.field === 'first_name'">
          {{ props.row.first_name }}
          <span v-if="props.row.nickname">({{ props.row.nickname }})</span>
        </span>

        <!-- Column: gender -->
        <span v-else-if="props.column.field === 'gender'">
          {{ renderGender(props.row.gender) }}
        </span>

        <!-- Column: dob -->
        <span v-else-if="props.column.field === 'dob'">
          {{ props.row.dob }}
          <div v-if="props.row.lunar_dob">
            {{ props.row.lunar_dob }}
            <br>
            {{ renderDayOfWeek(props.row.day_of_week) }} - {{ props.row.lunar_year_name }}
          </div>
        </span>

        <!-- Column: wa_day -->
        <span v-else-if="props.column.field === 'wa_day'">
          <div v-if="props.row.wa_day">
            Wed: {{ props.row.wa_day }}
            <br>
            Count: {{ props.row.wa_count_days }}
            <br>
            ({{ $commonFunc.diffDateForHuman(props.row.wa_day) }})
          </div>
          <div v-else>
            Không có
          </div>
        </span>

        <!-- Column: Special numerology count -->
        <span v-else-if="props.column.field === 'special_numerology_count'">
          Special count: {{ props.row.special_numerology_count }}
          <br>
          Birth {{ props.row.birth_number }}

          <template v-if="props.row.karmic_number > 0">
            <br>
            Karmic {{ props.row.karmic_number }}
          </template>
          <br>
          Life Path {{ props.row.life_path_number }}
          <br>
          Destiny {{ props.row.destiny_number }}
          <br>
          Soul {{ props.row.soul_number }}
          <br>
          Personality {{ props.row.personality_number }}
          <br>
          Karmic lessons {{ renderKarmicLessons(props.row.karmic_lessons) }}
          <br>
          Maturity {{ props.row.maturity_number }}
          <br>
          Attitude {{ props.row.attitude_number }}
          <br>
          Personal year {{ props.row.personal_year_number }}
          <br>
          <b-button
            v-b-modal.numerology-modal
            variant="outline-primary mt-1"
            @click="renderNumerologyContent(props.row.special_numerology_count,
                                            props.row.birth_number,
                                            props.row.karmic_number,
                                            props.row.life_path_number,
                                            props.row.destiny_number,
                                            props.row.soul_number,
                                            props.row.personality_number,
                                            props.row.karmic_lessons,
                                            props.row.maturity_number,
                                            props.row.attitude_number,
                                            props.row.personal_year_number)"
          >
            More detail
          </b-button>
        </span>

        <!-- Column: DOB left days -->
        <span v-else-if="props.column.field === 'dob_left_days'">
          {{ $commonFunc.leftDaysForHuman(props.row.dob_left_days) }}
          <br>
          {{ $commonFunc.weekDaysFromNow(props.row.dob_left_days) }}
        </span>

        <!-- Column: relationship_names -->
        <span v-else-if="props.column.field === 'relationship_names'">
          {{ renderRelationshipNames(props.row.relationship_names) }}
        </span>

        <!-- Column: n_arrow_raw -->
        <span
          v-else-if="props.column.field === 'n_arrow_raw'"
          style="white-space: pre-wrap"
        >{{ renderNameArrowRaw(props.row.n_arrow_raw) }}</span>

        <!-- Column: action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button
            size="sm"
            variant="info"
            target="_blank"
            class="action-btn"
            :to="{ path: '/person-infos/' + props.row.id }"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            size="sm"
            variant="danger"
            class="action-btn"
            @click="deletePersonInfoSwalAction(props.row.id)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              of {{ personInfos.total }} entries
            </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="personInfos && personInfos.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>
      </template>
    </vue-good-table>

    <b-modal
      id="numerology-modal"
      title="Numerology Info"
      ok-only
      ok-title="Accept"
      size="lg"
    >
      <b-card-text>
        <div
          v-for="(content, index) in numerologyContent"
          :key="index"
        >
          <h4 v-if="content[0] === '#'">
            <hr>
            {{ content.substring(1) }}
          </h4>
          <h5 v-else-if="content[0] === '*'">
            {{ content.substring(1) }}
          </h5>
          <p v-else>
            {{ content }}
          </p>
        </div>
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import { pagination } from '@core/mixins/pagination'
import ProcessNumerologyContent from '@core/libs/numerologyContent'

const { mapGetters, mapActions } = createNamespacedHelpers('personInfo')
const { mapActions: mapActionsNumerology } = createNamespacedHelpers('numerologies')

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [toastification, pagination],
  data() {
    return {
      searchTerm: '',
      sortTerm: {
        field: '',
        type: '',
      },
      columns: [
        { label: 'Last name', field: 'last_name' },
        { label: 'First name', field: 'first_name' },
        { label: 'Gender', field: 'gender' },
        { label: 'Birthday', field: 'dob', tdClass: 'text-nowrap' },
        { label: 'W-day', field: 'wa_day', tdClass: 'text-nowrap' },
        { label: 'Numerology', field: 'special_numerology_count', tdClass: 'text-nowrap' },
        { label: 'Age', field: 'age' },
        { label: 'DLD', field: 'dob_left_days' },
        { label: 'Horoscope', field: 'horoscope_sign_name' },
        { label: 'Heavenly Stem', field: 'heavenly_stem_name' },
        { label: 'Relationship', field: 'relationship_names' },
        { label: 'B_123', field: 'b_arrow_123' },
        { label: 'B_456', field: 'b_arrow_456' },
        { label: 'B_789', field: 'b_arrow_789' },
        { label: 'B_147', field: 'b_arrow_147' },
        { label: 'B_258', field: 'b_arrow_258' },
        { label: 'B_369', field: 'b_arrow_369' },
        { label: 'B_357', field: 'b_arrow_357' },
        { label: 'B_159', field: 'b_arrow_159' },
        { label: 'B_count', field: 'b_arrow_count' },
        { label: 'N_123', field: 'n_arrow_123' },
        { label: 'N_456', field: 'n_arrow_456' },
        { label: 'N_789', field: 'n_arrow_789' },
        { label: 'N_147', field: 'n_arrow_147' },
        { label: 'N_258', field: 'n_arrow_258' },
        { label: 'N_369', field: 'n_arrow_369' },
        { label: 'N_357', field: 'n_arrow_357' },
        { label: 'N_159', field: 'n_arrow_159' },
        { label: 'N_count', field: 'n_arrow_count' },
        { label: 'N_raw', field: 'n_arrow_raw', width: '300px', html: true },
        { label: 'Action', field: 'action' },
      ],
      numerologies: null,
      numerologyContent: [],
    }
  },
  computed: {
    ...mapGetters(['message', 'status', 'loading', 'personInfos']),
  },
  async created() {
    // await this.fetchPersonInfos()
    await this.getNumerologies()
    this.numerologies = JSON.parse(localStorage.getItem('numerology'))
  },
  methods: {
    ...mapActions(['getPersonInfos', 'deletePersonInfo', 'downloadPersonInfo']),
    ...mapActionsNumerology(['getNumerologies']),

    renderNumerologyContent(specialNumerologyCount, birthNumber, karmicNumber, lifePathNumber, destinyNumber, soulNumber, personalityNumber, karmicLessons, maturityNumber, attitudeNumber, personalYearNumber) {
      this.numerologyContent = ProcessNumerologyContent({
        numerologies: this.numerologies, specialNumerologyCount, birthNumber, karmicNumber, lifePathNumber, destinyNumber, soulNumber, personalityNumber, karmicLessons, maturityNumber, attitudeNumber, personalYearNumber,
      })
    },
    renderGender(gender) {
      return gender === 0 ? 'Nữ' : 'Nam'
    },
    renderRelationshipNames(relationshipNames) {
      return !relationshipNames ? '' : relationshipNames.split(',').join(', ')
    },
    renderDayOfWeek(dayOfWeek) {
      if (dayOfWeek === 1) {
        return 'CN'
      }
      return `T${dayOfWeek}`
    },
    renderKarmicLessons(karmicLessons) {
      return karmicLessons[0] ? karmicLessons.join(', ') : 'N/A'
    },
    renderNameArrowRaw(nameArrowRaw) {
      let html = ''
      const nameArrowRawObj = JSON.parse(nameArrowRaw)
      nameArrowRawObj.forEach((raw, index) => {
        if (index < nameArrowRawObj.length - 1) {
          const key = Object.keys(raw)[0]
          const val = raw[key]
          html += `${key}: [${val}] \n`
        } else {
          Object.entries(raw).sort((a, b) => b[1] - a[1]).forEach(summary => {
            html += `${summary[0]}: ${summary[1]} \n`
          })
        }
      })
      html += ''
      return html
    },
    async handleSearch(searching) {
      this.searchTerm = searching
      this.currentPage = 1
      await this.fetchPersonInfos()
    },
    async handlePageChange(active) {
      this.pageLength = active
      await this.fetchPersonInfos()
    },
    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchPersonInfos()
    },
    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type

      await this.fetchPersonInfos()
    },

    async fetchPersonInfos() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        sort:
          this.sortTerm.type === 'asc'
            ? `${this.sortTerm.field}`
            : `-${this.sortTerm.field}`,
        search: this.searchTerm,
      }
      await this.getPersonInfos(params)
    },
    async deletePersonInfoSwalAction(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await this.deletePersonInfoAction(id)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async deletePersonInfoAction(id) {
      await this.deletePersonInfo(id)
      if (this.status) {
        this.toastSuccess('Delete person info successfully')
        this.fetchPersonInfos()
      } else {
        this.toastFailure(this.message)
      }
    },
    async downloadCSV() {
      await this.downloadPersonInfo()
      if (this.status) {
        this.toastSuccess('Download person info CSV successfully')
        this.fetchPersonInfos()
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

export const pagination = {
  data() {
    return {
      pageLength: 10,
      currentPage: 1,
      pages: ['3', '5', '10', '20', '50'],
    }
  },
}

export const _ = null
